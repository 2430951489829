// @flow

const initialState = {
  isMobile: null,
  isOpen: false,
  posts: null,
  postId: null,
  postLinks: null,
};

export default function swiperModal(state = initialState, action) {
  switch (action.type) {
    case 'SHOW_MODAL':
      return {
        isOpen: true,
        isMobile: action.isMobile,
        posts: action.posts,
        postId: action.postId,
        postLinks: action.postLinks,
      };

    case 'HIDE_MODAL':
      return initialState;

    default:
      return state;
  }
}
