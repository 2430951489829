const baseTheme = {
  radii: [0, 2, 4, 8],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
};

const colors = {
  primary: '#65c6bd',
};

const shadows = [
  `0 0 1px rgba(67, 90, 111, 0.3)`,
  `0 0 1px rgba(67, 90, 111, 0.3), 0 2px 4px -2px rgba(67, 90, 111, 0.47)`,
  `0 0 1px rgba(67, 90, 111, 0.3), 0 5px 8px -4px rgba(67, 90, 111, 0.47)`,
  `0 0 1px rgba(67, 90, 111, 0.3), 0 8px 10px -4px rgba(67, 90, 111, 0.47)`,
  `0 0 1px rgba(67, 90, 111, 0.3), 0 16px 24px -8px rgba(67, 90, 111, 0.47)`,
];

export default {
  ...baseTheme,
  colors,
  shadows,
};
