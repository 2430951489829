// @flow

import * as React from 'react';
import { withTheme } from 'styled-components';

import {
  LoadingContainer,
  LoadingOverlay,
  LoadingPageContainer,
  Spinner,
} from './styles';

export function Loading({ color, size }: { color?: string, size?: number }) {
  return (
    <LoadingContainer>
      <Spinner color={color} size={size} />
    </LoadingContainer>
  );
}

export const LoadingGallery = withTheme(({ theme }) => {
  return (
    <LoadingOverlay>
      <Spinner color={theme.bg.default} size={32} />
    </LoadingOverlay>
  );
});

export function LoadingPage() {
  return (
    <LoadingPageContainer>
      <Spinner />
    </LoadingPageContainer>
  );
}
