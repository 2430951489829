import { combineReducers } from 'redux';

import gallery from './gallery';
import swiperModal from './swiperModal';
import shortnerGallery from './shortnerGallery';
import postModal from './postModal';

export default combineReducers({
  gallery,
  swiperModal,
  shortnerGallery,
  postModal,
});
