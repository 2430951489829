// @flow

export default {
  bg: {
    default: '#fff',
    reverse: '#16171a',
  },
  colors: {
    primary: '#65c6bd',
    pink: '#ee8085',
    yellow: '#fceed8',
    bg: {
      default: '#fff',
      reverse: '#16171a',
    },
  },
  lineHeights: [1, 1.125, 1.25, 1.5],
  radii: [0, 2, 4, 8],
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
  },
  text: {
    alt: '#828c99',
    default: '#212529',
    placeholder: '#a3afbf',
    reverse: '#fff',
    secondary: '#494c57',
  },
};
