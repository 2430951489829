export default new (function zIndex() {
  this.base = 1; // z-index: auto content will go here or inherit z-index from a parent

  this.fullscreen = 4000; // fullscreen elements should cover all screen content except toasts

  this.modal = 5000; // modals should completely cover base content and slider as well
  this.gallery = this.modal + 1; // gallery should never appear behind a modal

  this.toast = 6000; // toasts should be visible in every context
  this.tooltip = this.toast + 1; // tooltips should always be on top
})();
