const initialState = {
  isOpen: false,
  post: null,
};

export default function postModal(state = initialState, action) {
  switch (action.type) {
    case 'SHOW_POST_MODAL':
      return {
        isOpen: true,
        post: action.post,
      };

    case 'HIDE_POST_MODAL':
      return initialState;

    default:
      return state;
  }
}
