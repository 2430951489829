import React from 'react';
import ReactDOM from 'react-dom';

import App from './views/App';

import './raven';
import './icons';
import * as serviceWorker from './serviceWorker';

window.__isTouch = !matchMedia('(pointer:fine)').matches;

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

if (process.env.NODE_ENV === 'production') {
  serviceWorker.unregister({});
}
