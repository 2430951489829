import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming';
import * as React from 'react';
import { ApolloProvider } from 'react-apollo';
import Loadable from 'react-loadable';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import * as Sentry from '@sentry/browser';

import 'sanitize.css';

import apolloClient from '../../apollo';
import configureStore from '../../store';
import theme from '../../theme';
import themeNew from '../../theme-new';
import { LoadingPage } from '../../components/loading';

import '../../sass/app.css';
import './variables.css';

const store = configureStore();

const CampaignView = Loadable({
  loader: () => import('../Campaign' /* webpackChunkName: "campaign-view" */),
  loading: () => <LoadingPage />,
});

const PostView = Loadable({
  loader: () => import('../Post' /* webpackChunkName: "post-view" */),
  loading: () => <LoadingPage />,
});
const Lovlink = Loadable({
  loader: () => import('../Lovlink' /* webpackChunkName: "lovlink-view" */),
  loading: () => <LoadingPage />,
});
const BrandPosts = Loadable({
  loader: () => import('../Brand' /* webpackChunkName: "brand-view" */),
  loading: () => <LoadingPage />,
});

class App extends React.PureComponent<{}> {
  componentDidCatch(error, info) {
    Sentry.withScope((scope) => {
      scope.setExtras(info);

      Sentry.captureException(error);
    });
  }

  render() {
    return (
      <ReduxProvider store={store}>
        <ApolloProvider client={apolloClient}>
          <ThemeProvider theme={theme}>
            <EmotionThemeProvider theme={themeNew}>
              <BrowserRouter>
                <Switch>
                  <Route component={CampaignView} path={'/campaign/:hash'} />;
                  <Route component={PostView} path={'/post/:hash'} />;
                  <Route component={BrandPosts} path={'/brand/:hash'} />
                  <Route component={Lovlink} path={'/:hash'} />
                </Switch>
              </BrowserRouter>
            </EmotionThemeProvider>
          </ThemeProvider>
        </ApolloProvider>
      </ReduxProvider>
    );
  }
}

export default App;
