// @flow

const initialState = {
  entityId: null,
  isOpen: false,
  mediaId: null,
};

export default function gallery(state = initialState, action) {
  switch (action.type) {
    case 'SHOW_GALLERY':
      return {
        entityId: action.entityId,
        isOpen: true,
        mediaId: action.mediaId,
      };

    case 'HIDE_GALLERY':
      return initialState;

    default:
      return state;
  }
}
